import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Пейнтбол Dvrz
			</title>
			<meta name={"description"} content={"Найкращі враження від пейнтболу"} />
			<meta property={"og:title"} content={"Головна | Пейнтбол Dvrz"} />
			<meta property={"og:description"} content={"Найкращі враження від пейнтболу"} />
			<meta property={"og:image"} content={"https://nexialux.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nexialux.com/img/24334548.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nexialux.com/img/24334548.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nexialux.com/img/24334548.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nexialux.com/img/24334548.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nexialux.com/img/24334548.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nexialux.com/img/24334548.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Hero-16">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="0px 80px 0px 0px"
					lg-padding="0px 0 0px 0px"
					lg-margin="0px 0px 50px 0px"
					align-items="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Пейнтбол Dvrz
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
					Ласкаво просимо до Dvrz Paintball, найкращого місця для любителів пейнтболу всіх рівнів. Незалежно від того, чи ви досвідчений професіонал, чи граєте вперше, ми гарантуємо захоплюючу та незабутню пригоду. Наше найсучасніше обладнання та різноманітні ігрові сценарії створені для того, щоб забезпечити вам найкращі враження від гри в пейнтбол.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-self="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--primary"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							sm-width="100%"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Звʼязатися з нами
						</Button>
					</Box>
				</Box>
				<Image
					src="https://nexialux.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Чому варто обрати пейнтбол "Dvrz"?
				</Text>
				<Text
					font="--lead"
					color="--darkL2"
					text-align="center"
					md-width="100%"
					max-width="600px"
				>
					У Dvrz Paintball ми віримо у створення захоплюючого та динамічного середовища, де гравці можуть перевірити свої навички, розробити стратегію зі своєю командою та насолодитися гострими відчуттями від змагань. Наші просторі поля ретельно спроектовані з урахуванням різноманітності рельєфу та перешкод, що робить кожну гру унікальною та захоплюючою. Безпека є нашим головним пріоритетом, тому ми підтримуємо найвищі стандарти обслуговування обладнання та інструкцій для гравців, щоб забезпечити безпечну та приємну гру для всіх.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://nexialux.com/img/2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Різноманітні ігрові сценарії
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Наш різноманітний асортимент ігрових сценаріїв гарантує, що кожен візит - це нова пригода. Від класичного захоплення прапора до напружених командних смертельних поєдинків - кожен знайде щось для себе.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://nexialux.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Першокласне спорядження
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Ми надаємо високоякісні пейнтбольні рушниці, захисне спорядження та все необхідне обладнання, що гарантує безпечну та приємну гру.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" background="--color-light" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://nexialux.com/img/4.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Що робить нас особливими
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Окрім наших великих і різноманітних полів, Dvrz Paintball прагне надавати першокласний сервіс і неперевершений азарт. Наша команда досвідчених співробітників завжди готова надати рекомендації, підтримку та експертні поради, щоб покращити ваш ігровий процес. Ми обслуговуємо групи будь-якого розміру, від невеликих вечірок до великих корпоративних заходів, що робить нас ідеальним місцем для будь-якого випадку.
				</Text>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});